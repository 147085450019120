/* eslint-disable */
import React, { useEffect } from 'react';
import {
  ButtonGroup,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Grid,
  Link
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdministrators,
  isScheduler,
  isDoctorProvider
} from '../../helpers/permission-check';
import { getAllUserForm } from 'src/actions/userFormAction';
import { useNavigate } from 'react-router-dom';

function NavigationBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userForms, userFromDropdowns } = useSelector(
    (state) => state.userForm
  );

  const { user } = useSelector((state) => state.session);

  const filteredUserFromDropdowns = isAdministrators(user.userType) ? userFromDropdowns : userFromDropdowns.slice(1);
  useEffect(() => {
    const fetchUserForms = () => {
      if (!userForms || userForms.length === 0) {
        dispatch(getAllUserForm());
      }
    };

    fetchUserForms();
  }, [dispatch, userForms]);
  const appointmentsDropDown = [
    {
      name: 'Scheduled Patients',
      link: '/preps/patients/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71'
    },
    {
      name: 'Appointments Tracker',
      link: '/preps/appointments/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71'
    },
    { name: 'Appointments Slots', link: '/calendar' },
    {
      name: 'Office Schedule',
      link: '/provider-office-calendar'
    }
  ];

  const surgeryDropDown = [
    {
      name: 'Surgery Tracker',
      link: '/preps/surgery/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71'
    },
    { name: 'Calendar', link: '#' },
    { name: 'Preps', link: '#' }
  ];

  // const reviewsDropDown = [
  //   { name: 'Joshua Coursey, MD', link: '/contact-us-2/our-location/' },
  //   { name: 'Mark Edgcomb, DO', link: '/contact-us-2/our-location/' },
  //   { name: 'Rachel J Ellsworth, MD', link: '/contact-us-2/our-location/' },
  //   { name: 'Eric Haas, MD', link: '/contact-us-2/our-location/' },
  //   { name: 'Jean Paul LeFave, MD', link: '/preps/patients/51b44197-de19-4208-a4e5-6e7ed5721e2d' },
  //   { name: 'Matthew Weaver, MD', link: '/contact-us-2/our-location/' },
  //   { name: 'Virginia, NP', link: '/contact-us-2/our-location/' }
  // ];

  const designerDropDown = [
    { name: 'NEW Template', link: '/preps/create' },
    {
      name: 'FORM: Gen Reg',
      link: '/preps/form/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71'
    },
    {
      name: 'FORM: Colonoscopy Open Access',
      link: '/preps/form/77974a78-b6bf-4a98-8316-6c5528e808a1'
    },
    {
      name: 'VISIT: Colonoscopy Telehealth',
      link: '/preps/update/77974a78-b6bf-4a98-8316-6c5528e808a1'
    },
    {
      name: 'PREP: Colonoscopy',
      link: '/preps/update/1174a3fe-d848-4cc4-90f9-6411c004321b'
    },
    {
      name: 'FORM: Endo Bowel',
      link: '/preps/form/c63c9173-570f-49a4-ab80-4f4c6e203584'
    },
    {
      name: 'PREP: Endo Bowel',
      link: '/preps/update/c63c9173-570f-49a4-ab80-4f4c6e203584'
    },
    {
      name: 'FORM: Fecal Incontinence',
      link: '/preps/form/0fe1f3e8-ab60-4fdb-8f60-c7ed6c7621b7'
    },
    {
      name: 'PREP: Fecal Incontinence',
      link: '/preps/update/0fe1f3e8-ab60-4fdb-8f60-c7ed6c7621b7'
    },
    {
      name: 'FORM: Bowel Prep',
      link: '/preps/form/767ae719-6f95-4bf6-a620-242ca35d60b5'
    },
    {
      name: 'PREP: Bowel Prep',
      link: '/preps/update/767ae719-6f95-4bf6-a620-242ca35d60b5'
    }
  ];

  const anchorRef1 = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const anchorRef4 = React.useRef(null);
  const anchorRef5 = React.useRef(null);
  const anchorRef6 = React.useRef(null);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const { userType } = useSelector((state) => state.session);
  const menuItems = [];
  if (isAdministrators(userType)) {
    menuItems.push({
      item: 'Dashboard',
      dropDownList: [],
      ref: anchorRef1,
      open: open1,
      setOpen: (isOpen) => {
        setOpen1(isOpen);
      },
      handleToggle: () => {
        setOpen1((prevOpen) => !prevOpen);
      },
      goTo: () => {
        window.location.href = '/dashboard';
      }
    });
  }
  menuItems.push({
    item: 'Appointments',
    dropDownList: appointmentsDropDown,
    ref: anchorRef2,
    open: open2,
    setOpen: (isOpen) => {
      setOpen2(isOpen);
    },
    handleToggle: () => {
      setOpen2((prevOpen) => !prevOpen);
    }
  });
  if (
    isAdministrators(userType) ||
    isScheduler(userType) ||
    isDoctorProvider(userType)
  ) {
    menuItems.push({
      item: 'Forms',
      dropDownList: filteredUserFromDropdowns,
      // dropDownList: userFromDropdowns,
      // dropDownList: surgeryDropDown,
      ref: anchorRef3,
      open: open3,
      setOpen: (isOpen) => {
        setOpen3(isOpen);
      },
      handleToggle: () => {
        setOpen3((prevOpen) => !prevOpen);
      }
    });
  }

  menuItems.push({
    item: 'Providers',
    dropDownList: [],
    ref: anchorRef4,
    open: open4,
    setOpen: (isOpen) => {
      setOpen4(isOpen);
    },
    handleToggle: () => {
      setOpen4((prevOpen) => !prevOpen);
    },
    goTo: () => {
      window.location.href =
        '/preps/referring-providers/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71';
    }
  });
  menuItems.push({
    item: 'Reviews',
    dropDownList: [],
    ref: anchorRef5,
    open: open5,
    setOpen: (isOpen) => {
      setOpen5(isOpen);
    },
    handleToggle: () => {
      setOpen5((prevOpen) => !prevOpen);
    },
    goTo: () => {
      window.location.href =
        '/preps/reviews/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71';
    }
  });
  if (
    isAdministrators(userType) ||
    isDoctorProvider(userType) ||
    isScheduler(userType)
  ) {
    menuItems.push({
      item: 'Designer',
      dropDownList: designerDropDown,
      ref: anchorRef6,
      open: open6,
      setOpen: (isOpen) => {
        setOpen6(isOpen);
      },
      handleToggle: () => {
        setOpen6((prevOpen) => !prevOpen);
      }
    });
  }
  // ];
  const [open, setOpen] = React.useState(false);
  // const anchorRef = React.useRef(null);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
    >
      {menuItems.map((element, index) => (
        <Button
          key={index}
          size="large"
          ref={element.ref}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => {
            if (element.dropDownList.length > 0) {
              element.handleToggle();
            } else {
              element.goTo();
            }
          }}
        >
          {element.item}
        </Button>
      ))}
      {menuItems.map(
        (element, index) =>
          element.dropDownList.length > 0 && (
            <Popper
              key={index}
              open={element.open}
              anchorEl={element.ref.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(event) => {
                        if (
                          element.ref.current &&
                          element.ref.current.contains(event.target)
                        ) {
                          return;
                        }

                        element.setOpen(false);
                      }}
                    >
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        keepMounted
                      >
                        <Grid container spacing={0} direction="column">
                          {element.dropDownList.map((dropDownItem) => (
                            <Grid item xs={12}>
                              <MenuItem
                                onClick={() => {
                                  navigate(dropDownItem.link);
                                  setOpen3(false);
                                  // window.location.href = dropDownItem.link;
                                }}
                              >
                                {dropDownItem.name}
                              </MenuItem>
                            </Grid>
                          ))}
                        </Grid>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )
      )}
    </ButtonGroup>
  );
}

export default NavigationBar;
