/* eslint-disable */
import * as actionTypes from 'src/actions/actionTypes';

const initialState = {
  userFormDataChanges: [],
  userFormsData: [],
  hasServerError: false,
  serverMessage: '',
  loading: true,
  openAlert: false,
  refreshData: 0,
  cancelTemplates: [],
  userFormDataRender: {}
};

const userFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_USER_FORM_DATA_FIELD:
      const newList = state.userFormDataChanges;
      const index = state.userFormDataChanges.findIndex((i) => i.localId === action.payload.localId && i.patientId === action.payload.patientId);

      if (index !== -1) {
        newList[index] = { ...state.userFormDataChanges[index], ...action.payload };
      } else {
        newList.push(action.payload);
      }
      return {
        ...state,
        userFormDataChanges: newList
      };

    case actionTypes.RESET_USER_FORM_DATA_FIELD:
      return {
        ...state,
        userFormDataChanges: [],
        userFormsData: [],
        refreshData: state.refreshData + 1
      };

    case actionTypes.USER_FORM_DATA_PENDING:
      return {
        ...state,
        loading: true,
        openAlert: false,
      };

    case actionTypes.SAVE_USER_FORM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userFormDataChanges: [],
        openAlert: true,
        serverMessage: action.serverMessage,
        cancelTemplates: [],
      };

    case actionTypes.SAVE_USER_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        userFormDataChanges: [],
        hasServerError: true,
        serverMessage: action.serverMessage,
        openAlert: true
      };

    case actionTypes.GET_ALL_USER_FORM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userFormsData: [...action.userFormsData]
      };

    case actionTypes.GET_ALL_USER_FORM_DATA_SUCCESS_APPEND:
      return {
        ...state,
        loading: false,
        userFormsData: [...state.userFormsData, ...action.userFormsData]
      };

    case actionTypes.GET_ALL_USER_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        userFormDataRender: action.userFormsData
      };

    case actionTypes.GET_USER_FORM_DATA_BY_PATIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userFormDataRender: action.userFormsData
      };

    case actionTypes.GET_ALL_USER_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        userFormsData: []
      };

    case actionTypes.USER_FORM_DATA_REMOVE_PATIENT_BY_ID:
      const { patientId } = action.payload;
      const updatedUserFormsData = state.userFormsData.map((userFormData) => {
        if (userFormData.patientId === patientId) {
          return { ...userFormData, prepInstructions: null };
        }
        return userFormData;
      });
      const cancelTemplatesUpdated = !state.cancelTemplates.includes(patientId) ? [...state.cancelTemplates, patientId] : state.cancelTemplates;
      return {
        ...state,
        userFormsData: updatedUserFormsData,
        cancelTemplates: cancelTemplatesUpdated,
      };

    default:
      return { ...state };
  }
};

export default userFormDataReducer;
