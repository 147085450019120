/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  collaboration: stringFormatter.formatUrl('/api/collaborations'),
  messageEvent: stringFormatter.formatUrl('/api/messageEvents'),
  queryType: stringFormatter.formatUrl('/api/queryType'),
  publicPatientForm: stringFormatter.formatUrl('/api/publicPatientForm'),
  userForm: stringFormatter.formatUrl('/api/userForm'),
  previewTemplate: stringFormatter.formatUrl('/api/collaborations/preview'),
};

const getCollaborations = () => axiosInstance.get(`${endpoints.collaboration}?type=2`);
const getCollaborationForPrep = () => axiosInstance.get(`${endpoints.collaboration}?type=1`);

const getAllCollaborations = () => axiosInstance.get(`${endpoints.collaboration}/all`);

const getQueryTypes = () => axiosInstance.get(endpoints.queryType);

const createCollaboration = (collaboration) => axiosInstance.post(
  endpoints.collaboration,
  collaboration
);

const updateCollaboration = (collaboration) => axiosInstance.put(`${endpoints.collaboration}/${collaboration.localId}`, collaboration);

const deleteCollaboration = (localId) => axiosInstance.delete(`${endpoints.collaboration}/${localId}`);

const fetchPreviewTemplate = (localId, type) => axiosInstance.get(`${endpoints.previewTemplate}/${type}/${localId}`);

const createMessageEvent = (prepId, messageEvents) => {
  const collaborationId = prepId;
  const { day } = messageEvents;
  const { time } = messageEvents;
  const qrLabel = 'No things';
  const qrStep = 3;
  const qrInstruction = 'No things';
  const attachedImage = 'https://www.bigstockphoto.com/images/homepage/module-6.jpg';
  const { message } = messageEvents;
  const { messageEventQuery } = messageEvents;

  const payload = {
    collaborationId,
    day,
    time,
    qrLabel,
    qrStep,
    qrInstruction,
    attachedImage,
    // time,
    message,
    messageEventQuery
  };
  return axiosInstance.post(endpoints.messageEvent, payload);
};

const updateMessageEvent = (messageEvent) => axiosInstance.post(
  endpoints.messageEvent,
  messageEvent
);

const getCollaborationById = (prepId) => axiosInstance.get(`${endpoints.collaboration}/${prepId}`);

const getMessageEventByCollaboration = (prepId) => axiosInstance.get(`${endpoints.messageEvent}?collaborationId=${prepId}`);

const duplicateCollaboration = (prepId) => axiosInstance.post(`${endpoints.collaboration}/duplicate?collaborationId=${prepId}`);

const fetchPublicPatientForm = (prepId) => axiosInstance.get(`${endpoints.publicPatientForm}?collaborationId=${prepId}`);

const createPublicPatientForm = (patientFormData) => axiosInstance.post(endpoints.publicPatientForm, patientFormData);

const fetchUserForm = (prepId) => axiosInstance.get(`${endpoints.userForm}?collaborationId=${prepId}`);

const createUserForm = (patientFormData) => axiosInstance.post(endpoints.userForm, patientFormData);

const updateMessageEventById = (messageEvent) => axiosInstance.put(`${endpoints.messageEvent}/${messageEvent.localId}`, messageEvent);
// eslint-disable-next-line import/prefer-default-export
export const prepService = {
  getCollaborations,
  createCollaboration,
  createMessageEvent,
  getCollaborationById,
  updateCollaboration,
  deleteCollaboration,
  updateMessageEvent,
  getMessageEventByCollaboration,
  getQueryTypes,
  duplicateCollaboration,
  getAllCollaborations,
  fetchPublicPatientForm,
  createPublicPatientForm,
  fetchPreviewTemplate,
  getCollaborationForPrep,
  updateMessageEventById,
  createUserForm,
  fetchUserForm,
};
