/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, useParams } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import LandingPage from './layouts/LandingPage';
import NoLoginLayout from './layouts/NoLogin';
import PatientLayout from './layouts/Patient';

import Test from './views/Test';
import Loading from './views/Loading';

const Login = lazy(() => import('src/views/Login'));
const Verify = lazy(() => import('src/views/Verify'));
const RequestResendOTP = lazy(() => import('src/views/RequestResendOTP'));
const Register = lazy(() => import('src/views/Register'));
const ReviewForm = lazy(() => import('src/views/Review-form'));
const RequestResetPassword = lazy(
  () => import('src/views/RequestResetPassword')
);
const ResetPassword = lazy(() => import('src/views/ResetPassword'));
const Token = lazy(() => import('src/views/Token'));
const PatientCalendar = lazy(() => import('src/views/PatientCalendar'));
const CreateNewPatientNoLogin = lazy(
  () => import('src/views/CreateNewPatientNoLogin')
);
const PatientForm = lazy(
  () => import('src/views/CreateNewPatientNoLogin/PatientForm')
);
const Error401 = lazy(() => import('src/views/Error401'));
const Error404 = lazy(() => import('src/views/Error404'));
const Error500 = lazy(() => import('src/views/Error500'));

const PrepChat = lazy(() => import('src/views/PrepChat'));
const Appointment = lazy(() => import('src/views/Appointment'));
const Surgery = lazy(() => import('src/views/Surgery'));
const SurgeryUserForm = lazy(() => import('src/views/UserFormData'));
const CreateNewPatient = lazy(
  () => import('src/views/Surgery/CreateNewProcedure')
);
const CreateNewPatientUserForm = lazy(
  () => import('src/views/UserFormData/CreateNewProcedure')
);
const Referrals = lazy(() => import('src/views/Referrals'));
const AllPreps = lazy(() => import('src/views/AllPreps'));
const UserCalendar = lazy(() => import('src/views/UserCalendar'));
const ProviderOfficeCalendar = lazy(
  () => import('src/views/ProviderOfficeCalendar')
);
const CreatePrep = lazy(() => import('src/views/CreatePrep'));
const CreatePatient = lazy(() => import('src/views/CreatePatient'));
const PatientDetails = lazy(() => import('src/views/PatientDetails'));
const PatientDetailsUserForm = lazy(
  () => import('src/views/Surgery/PatientDetails')
);
const PrepForm = lazy(() => import('src/views/PrepForm'));
const ChatOfPrepChat = lazy(() => import('src/views/PrepChat/Chat'));
const Calendar = lazy(() => import('src/views/Calendar'));
const Profile = lazy(() => import('src/views/Profile'));
const SignOut = lazy(() => import('src/views/SignOut'));
const Review = lazy(() => import('src/views/Reviews'));

const token = localStorage.getItem('token');

export default createBrowserRouter([
  {
    path: '/',
    element: token ? (
      <Navigate to="/preps/patients/a25ca1bd-7b62-42ed-9f3b-9e4dc35d9d71" />
    ) : (
      <Navigate to="/auth/login" />
    )
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: '/auth/login',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        )
      },
      {
        path: '/auth/verify',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Verify />
          </Suspense>
        )
      },
      {
        path: '/auth/request-resend-otp',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <RequestResendOTP />
          </Suspense>
        )
      },
      {
        path: '/auth/register/:businessId',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Register />
          </Suspense>
        )
      },
      {
        path: '/auth/review',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <ReviewForm />
          </Suspense>
        )
      },
      {
        path: '/auth/request-reset-password',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <RequestResetPassword />
          </Suspense>
        )
      },
      {
        path: '/auth/reset-password/:id/:token',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <ResetPassword />
          </Suspense>
        )
      },
      {
        path: '/auth/token/:token',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Token />
          </Suspense>
        )
      },
      {
        element: () => <Navigate to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/patients',
    element: <PatientLayout />,
    children: [
      {
        path: '/patients/:id',
        exact: true,
        element: lazy(() => import('src/views/Patients'))
      },
      {
        element: () => <Navigate to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/calendars',
    element: <PatientLayout />,
    children: [
      {
        path: '/calendars/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PatientCalendar />
          </Suspense>
        )
      },
      {
        element: () => <Navigate to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/client',
    element: <NoLoginLayout />,
    children: [
      {
        path: '/client/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Error404 />
          </Suspense>
        )
      },
      {
        path: '/client/testing/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreateNewPatientNoLogin />
          </Suspense>
        )
      },
      {
        path: '/client/medical-form/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PatientForm />
          </Suspense>
        )
      },
      {
        path: '/client/user-form/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PatientDetailsUserForm />
          </Suspense>
        )
      },
      {
        element: () => <Navigate to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    element: <ErrorLayout />,
    children: [
      {
        path: '/errors/error-401',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Error401 />
          </Suspense>
        )
      },
      {
        path: '/errors/error-404',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Error404 />
          </Suspense>
        )
      },
      {
        path: '/errors/error-500',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Error500 />
          </Suspense>
        )
      },
      {
        element: () => <Navigate to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/test',
    element: <Test />
  },
  {
    path: '/appointments',
    element: <LandingPage />
  },
  {
    route: '*',
    element: <DashboardLayout />,
    children: [
      {
        path: '/preps/patients',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PrepChat />
          </Suspense>
        )
      },
      {
        path: '/preps/appointments/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Appointment />
          </Suspense>
        )
      },
      {
        path: '/preps/surgery/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Surgery />
          </Suspense>
        )
      },
      {
        path: '/preps/:shortName/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <SurgeryUserForm />
          </Suspense>
        )
      },
      {
        path: '/preps/surgery/create-patient/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreateNewPatient />
          </Suspense>
        )
      },
      {
        path: '/preps/:shortName/create-patient/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreateNewPatientUserForm />
          </Suspense>
        )
      },
      {
        path: '/preps/surgery/edit-patient/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreateNewPatient />
          </Suspense>
        )
      },
      {
        path: '/preps/:shortName/edit-patient/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreateNewPatientUserForm />
          </Suspense>
        )
      },
      {
        path: '/preps/user-form/create',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreatePrep />
          </Suspense>
        )
      },
      {
        path: '/preps/referring-providers/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Referrals />
          </Suspense>
        )
      },
      {
        path: '/allpreps',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <AllPreps />
          </Suspense>
        )
      },
      {
        path: '/calendar',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <UserCalendar />
          </Suspense>
        )
      },
      {
        path: '/provider-office-calendar',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <ProviderOfficeCalendar />
          </Suspense>
        )
      },
      {
        path: '/preps/create',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreatePrep />
          </Suspense>
        )
      },
      {
        path: '/preps/update/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreatePrep />
          </Suspense>
        )
      },
      {
        path: '/preps/patients/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PrepChat />
          </Suspense>
        )
      },
      {
        path: '/preps/create-patient/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreatePatient />
          </Suspense>
        )
      },
      {
        path: '/preps/edit-patient/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <CreatePatient />
          </Suspense>
        )
      },
      {
        path: '/preps/patient-details/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PatientDetails />
          </Suspense>
        )
      },
      {
        path: '/preps/form/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PrepForm />
          </Suspense>
        )
      },
      {
        path: '/preps/user-form/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <PrepForm />
          </Suspense>
        )
      },
      {
        path: '/prep/colonoscopy/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <ChatOfPrepChat />
          </Suspense>
        )
      },
      {
        path: '/calendar',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Calendar />
          </Suspense>
        )
      },
      {
        path: '/profile',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Profile />
          </Suspense>
        )
      },
      // {
      //   path: '/dashboard',
      //   exact: true,
      //   element: lazy(() => import('src/views/Dashboard'))
      // },
      {
        path: '/add-user',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Register />
          </Suspense>
        )
      },
      {
        path: '/profile/:tab',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Profile />
          </Suspense>
        )
      },
      {
        path: '/signout',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <SignOut />
          </Suspense>
        )
      },
      {
        path: '/preps/reviews/:id',
        exact: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Review />
          </Suspense>
        )
      },
      {
        element: () => <Navigate to="/errors/error-404" />
      }
    ]
  }
]);
