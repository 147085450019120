/* eslint-disable */
import { filterLatestObject } from 'src/utils/helper';
import * as actionTypes from './actionTypes';
import { prepService } from '../services';
import collaborationType from 'src/constant/conllaborationTypes';

export const selectPrep = (selectedPrep) => (dispatch) => {
  dispatch({ type: actionTypes.SELECTED_PREP });
  dispatch({ type: actionTypes.SELECTED_PREP_SUCCESS, selectedPrep });
};

export const changePrepField = (
  name,
  value,
  MessageEventNumber = null,
  level = null,
  childrenNumber = null,
  subChildrenNumber = null,
  isInit = true
) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_PREP_FIELD,
    name,
    value,
    MessageEventNumber,
    level,
    childrenNumber,
    subChildrenNumber,
    isInit
  });
};

export const removeChangePrepField = () => (dispatch) => {
  dispatch({ type: actionTypes.REMOVE_CHANGE_PREP_FIELD });
};

export const getQueryTypes = () => (dispatch) => {
  prepService.getQueryTypes()
    .then(
      (response) => {
        const { data } = response;
        dispatch({ type: actionTypes.GET_QUERY_TYPE_SUCCESS, data });
      },
      (error) => {
        dispatch({ type: actionTypes.GET_QUERY_TYPE_FAILURE, error });
      }
    );
};

export const getCollaborations = (from, history) => (dispatch) => {
  if (from === 'surgery') {
    prepService.getCollaborationForPrep().then(
      (response) => {
        const collaborations = response.data;
        dispatch({ type: actionTypes.GET_PREP_SUCCESS, collaborations });
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
        }
        dispatch({ type: actionTypes.GET_PREP_FAILURE });
      }
    );
  } else if (history) {
    prepService.getCollaborations()
      .then(
        (response) => {
          const collaborations = response.data;
          const prepLatest = filterLatestObject(collaborations);
          dispatch({ type: actionTypes.GET_PREP_SUCCESS, collaborations });
          history(`/preps/update/${prepLatest.localId}`);
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
          }
          dispatch({ type: actionTypes.GET_PREP_FAILURE });
        }
      );
  } else {
    prepService.getCollaborations()
      .then(
        (response) => {
          const collaborations = response.data;
          dispatch({ type: actionTypes.GET_PREP_SUCCESS, collaborations });
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
          }
          dispatch({ type: actionTypes.GET_PREP_FAILURE });
        }
      );
  }
};

const isValid = (collaboration, dispatch, actionType) => {
  dispatch({ type: actionTypes.RESET_VALIDATION, serverErrorMessage: undefined });
  const {
    name,
    startDate,
    endDate,
    messageEvents
  } = collaboration;

  if (!name) {
    dispatch({ type: actionType, serverErrorMessage: 'The name field is required.' });
    return false;
  } if (!startDate) {
    dispatch({ type: actionType, serverErrorMessage: 'Start Date field is required.' });
    return false;
  } if (!endDate) {
    dispatch({ type: actionType, serverErrorMessage: 'Start Date field is required.' });
    return false;
  }
  if (messageEvents && messageEvents.length) {
    for (let i = 0; i < messageEvents.length; i++) {
      if (!messageEvents[i].immediately) {
        if (messageEvents[i].day === undefined
          || messageEvents[i].day === null
          // eslint-disable-next-line no-restricted-globals
          || isNaN(messageEvents[i].day)) {
          dispatch({ type: actionType, serverErrorMessage: `Event ${i + 1} - Day (-/+) field is required.` });
          return false;
        }

        if (!Number.isInteger(Number(messageEvents[i].day))) {
          dispatch({ type: actionType, serverErrorMessage: `Event ${i + 1} - Day (-/+) field must be an integer.` });
          return false;
        }
      }
    }
  }

  return true;
};

const preProcess = (payload) => {
  const result = {
    ...payload
  };

  if (result.description_updating) {
    result.description = result.description_updating;
  }

  for (let i = 0; i < result.messageEvents.length; i++) {
    if (result.messageEvents[i].messageEventQuery.content_updating != null
      && typeof (result.messageEvents[i].messageEventQuery.content_updating) === 'string') {
      // eslint-disable-next-line max-len
      result.messageEvents[i].messageEventQuery.content = result.messageEvents[i].messageEventQuery.content_updating;
    }

    if (result.messageEvents[i].messageEventQuery.children.length) {
      for (let j = 0; j < result.messageEvents[i].messageEventQuery.children.length; j++) {
        if (result.messageEvents[i]
          .messageEventQuery
          .children[j]
          .content_updating) {
          result.messageEvents[i].messageEventQuery.children[j].content = result.messageEvents[i]
            .messageEventQuery
            .children[j]
            .content_updating;
        }

        if (result.messageEvents[i].messageEventQuery.children[j].children.length) {
          for (let x = 0;
            x < result.messageEvents[i].messageEventQuery.children[j].children.length;
            x++) {
            if (result.messageEvents[i]
              .messageEventQuery
              .children[j]
              .children[x]
              .content_updating) {
              result.messageEvents[i]
                .messageEventQuery
                .children[j]
                .children[x].content = result.messageEvents[i]
                  .messageEventQuery
                  .children[j]
                  .children[x].content_updating;
            }
          }
        }
      }
    }
  }

  return result;
};

// eslint-disable-next-line no-unused-vars
export const createCollaboration = (collaboration, history) => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_CREATE_PREP_LOADING });
  dispatch({
    type: actionTypes.CREATE_PREP
  });

  const {
    name,
    description_updating,
    thumbnail,
    startDate,
    endDate,
    fee,
    privacyAllowClinicalTeamToInviteOthers,
    privacyDoubleBlindStudy,
    messageEvents,
    welcomeSMS,
    type
  } = collaboration;

  if (!isValid(collaboration, dispatch, actionTypes.CREATE_PREP_FAILURE)) {
    return;
  }

  let payload = {
    name,
    description_updating,
    thumbnail,
    startDate,
    endDate,
    fee,
    privacyAllowClinicalTeamToInviteOthers,
    privacyDoubleBlindStudy,
    messageEvents,
    welcomeSMS,
    type
  };

  payload = preProcess(payload);

  prepService.createCollaboration(payload)
    .then(
      async (response) => {
        const { data } = response;
        await dispatch({ type: actionTypes.CREATE_PREP_SUCCESS, data });
        await dispatch(selectPrep(data.localId));
        dispatch(getCollaborations());
        // history.push(`/preps/update/${data.localId}`);
        const createPrepFormSuccess = 'Created template successfully.';
        dispatch({ type: actionTypes.CREATE_PREP_FORM_SUCCESS, message: createPrepFormSuccess });
        localStorage.setItem('selectedPrep', data.localId);
        if (type === collaborationType.USER_FORM) {
          history(`/preps/user-form/${data.localId}`);
        }
      },
      (error) => {
        dispatch({ type: actionTypes.CREATE_PREP_FAILURE, error });
      }
    );
};

// eslint-disable-next-line no-unused-vars
export const updateCollaboration = (collaboration, history) => (dispatch) => {
  if (!isValid(collaboration, dispatch, actionTypes.UPDATE_PREP_FAILURE)) {
    return;
  }

  dispatch({ type: actionTypes.SHOW_CREATE_PREP_LOADING });

  const {
    localId,
    businessId,
    name,
    description_updating,
    thumbnail,
    startDate,
    endDate,
    fee,
    privacyAllowClinicalTeamToInviteOthers,
    privacyDoubleBlindStudy,
    messageEvents,
    welcomeSMS
  } = collaboration;

  let payload = {
    localId,
    businessId,
    name,
    description_updating,
    thumbnail,
    startDate,
    endDate,
    fee,
    privacyAllowClinicalTeamToInviteOthers,
    privacyDoubleBlindStudy,
    messageEvents,
    welcomeSMS
  };

  payload = preProcess(payload);

  prepService.updateCollaboration(payload)
    .then(
      async (response) => {
        const { data } = response;
        await dispatch({ type: actionTypes.UPDATE_PREP_SUCCESS, data });
        await dispatch(selectPrep(data.localId));
        dispatch(getCollaborations());
        // history.push(`/preps/patients/${data.localId}`);
        const createPrepFormSuccess = 'Updated template successfully.';
        dispatch({ type: actionTypes.CREATE_PREP_FORM_SUCCESS, message: createPrepFormSuccess });
        localStorage.setItem('selectedPrep', data.localId);
      },
      (error) => {
        dispatch({ type: actionTypes.UPDATE_PREP_FAILURE, error });
      }
    )
    .finally(() => {
      history(".", { relative: "path" });
    });
};

export const deleteCollaboration = (localId, history) => (dispatch) => {
  prepService.deleteCollaboration(localId)
    .then(
      async () => {
        history(".", { relative: "path" });
      },
      (error) => {
        dispatch({ type: actionTypes.DELETE_PREP_FAILURE, error });
      }
    );
};

export const getCollaborationById = (prepId) => (dispatch) => {
  prepService.getCollaborationById(prepId)
    .then(
      async (response) => {
        const { data } = response;
        dispatch({ type: actionTypes.GET_PREP_BY_ID_SUCCESS, data });
      },
      (error) => {
      // eslint-disable-next-line no-console
        console.log(error);
      }
    );
};

export const addMessageEvent = (collaborationId, newMessageEvent) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_MESSAGE_EVENT, payload: { collaborationId, newMessageEvent }
  });
};

export const setBuildTreeNodes = (isBuild) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BUILD_TREE_NODES, payload: { isBuild }
  });
};

export const removeMessageEvent = (index, messageEvents) => (dispatch) => {
  messageEvents.splice(index, 1);
  dispatch({
    type: actionTypes.REMOVE_MESSAGE_EVENT, payload: { messageEvents }
  });
};

export const addQueryType = (
  value,
  MessageEventNumber = null,
  level = null,
  childrenNumber = null
) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_QUERY_TYPE,
    value,
    MessageEventNumber,
    level,
    childrenNumber
  });
};

export const duplicateCollaboration = (prepId, history) => (dispatch) => {
  prepService.duplicateCollaboration(prepId)
    .then(
      async () => {
        await dispatch(getCollaborations(null, history));
      },
      (error) => {
      // eslint-disable-next-line no-console
        console.log(error);
      }
    );
};

export const getAllCollaborations = () => (dispatch) => {
  prepService.getAllCollaborations()
    .then(
      async (response) => {
        const { data } = response;
        dispatch({
          type: actionTypes.FETCH_ALL_PREPS_SUCCESS,
          data
        });
      },
      (error) => {
      // eslint-disable-next-line no-console
        console.log(error);
      }
    );
};

export const uploadThumbnailSuccessfully = (imageUrl) => (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_PREP_FIELD,
    name: 'thumbnail',
    value: imageUrl,
    MessageEventNumber: null,
    level: null,
    childrenNumber: null,
    subChildrenNumber: null,
    isInt: false
  });
};

export const showUploadThumbnailModal = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_UPLOAD_THUMBNAIL_MODAL,
    value
  });
};

export const fetchPrepForm = ({
  id,
  type
}) => (dispatch) => {
  if (type === collaborationType.PUBLIC_PATIENT_FORM) {
    prepService.fetchPublicPatientForm(id)
    .then((response) => {
      const prepForm = response.data;
      dispatch({ type: actionTypes.FETCH_PREP_FORM_SUCCESS, prepForm });
    });
  } else {
    prepService.fetchUserForm(id)
    .then((response) => {
      const prepForm = response.data;
      dispatch({ type: actionTypes.FETCH_PREP_FORM_SUCCESS, prepForm });
    });
  }
 
};

export const createPublicPatientForm = (prepId, patientFormData, endpointURL, history) => (dispatch) => {
  const prepData = {
    collaborationId: prepId,
    fieldData: patientFormData,
    endpointUrl: endpointURL
  };

  prepService.createPublicPatientForm(prepData)
    .then(
      () => {
        const createPrepFormSuccess = 'Public patient form saved successfully.';
        dispatch({ type: actionTypes.CREATE_PREP_FORM_SUCCESS, message: createPrepFormSuccess });
      },
      (error) => {
        dispatch({ type: actionTypes.CREATE_PREP_FORM_FAILURE, message: error.response.data.message });
      }
    )
    .finally(() => {
      history('/allpreps');
    });
};

export const createUserForm = ({ prepId, patientFormData, history}) => (dispatch) => {
  const prepData = {
    collaborationId: prepId,
    fieldData: patientFormData,
  };

  prepService.createUserForm(prepData)
    .then(
      () => {
        const createPrepFormSuccess = 'User form saved successfully.';
        dispatch({ type: actionTypes.CREATE_PREP_FORM_SUCCESS, message: createPrepFormSuccess });
      },
      (error) => {
        dispatch({ type: actionTypes.CREATE_PREP_FORM_FAILURE, message: error.response.data.message });
      }
    )
    .finally(() => {
      history('/allpreps');
    });
};

export const listPatientChanged = () => (dispatch) => {
  dispatch({ type: actionTypes.SET_LIST_PATIENT_CHANGED });
};
