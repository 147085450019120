/* eslint-disable */
import { userFormService } from 'src/services';

import * as actionTypes from './actionTypes';

export const getAllUserForm = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.USER_FORM_PENDING });
  const data = await userFormService.getAllUserForm();
  if (data.status === 200) {
    dispatch({
      type: actionTypes.GET_ALL_USER_FORM_SUCCESS,
      userForms: data.data
    });
  } else {
    dispatch({
      type: actionTypes.GET_ALL_USER_FORM_FAILURE,
      serverMessage: data.data.message
    });
  }
};
