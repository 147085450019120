/* eslint-disable max-len */
import { axiosInstance } from 'src/helpers/auth-header';
import stringFormatter from '../utils/stringFormatter';

const endpoints = {
  getAllUserForm: stringFormatter.formatUrl('/api/userForm'),
};
const getAllUserForm = () => axiosInstance.get(`${endpoints.getAllUserForm}`);
const getUserFormByCollaborationId = (collaborationId) => axiosInstance.get(`${endpoints.getAllUserForm}?collaborationId=${collaborationId}`);

export const userFormService = {
  getAllUserForm,
  getUserFormByCollaborationId
};

export default {};
