export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS';
export const SESSION_LOGIN_FAILURE = 'SESSION_LOGIN_FAILURE';
export const SESSION_TWO_FACTOR_AUTH_SUCCESS = 'SESSION_TWO_FACTOR_AUTH_SUCCESS';
export const SESSION_TWO_FACTOR_AUTH_FAILURE = 'SESSION_TWO_FACTOR_AUTH_FAILURE';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_ACCOUNT_LOCKOUT = 'SESSION_ACCOUNT_LOCKOUT';
export const SESSION_ACCOUNT_LOCKOUT_10M = 'SESSION_ACCOUNT_LOCKOUT_10M';
export const SESSION_ACCOUNT_LOCKOUT_1ST = 'SESSION_ACCOUNT_LOCKOUT_1ST';
export const SESSION_ACCOUNT_LOCKOUT_2ND = 'SESSION_ACCOUNT_LOCKOUT_2ND';

export const SESSION_TWO_FACTOR_AUTH = 'SESSION_TWO_FACTOR_AUTH';
export const SESSION_OTP_DOES_NOT_MATCH = 'SESSION_OTP_DOES_NOT_MATCH';
export const SESSION_OTP_HAS_BEEN_USED = 'SESSION_OTP_HAS_BEEN_USED';
export const SESSION_OTP_HAS_EXPIRED = 'SESSION_OTP_HAS_EXPIRED';
export const SESSION_OTP_SPAM = 'SESSION_OTP_SPAM';

export const SESSION_REGISTER = 'SESSION_REGISTER';
export const SESSION_REGISTER_SUCCESS = 'SESSION_REGISTER_SUCCESS';
export const SESSION_REGISTER_FAILURE = 'SESSION_REGISTER_FAILURE';

export const SESSION_UPDATE = 'SESSION_UPDATE';
export const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS';
export const SESSION_UPDATE_FAILURE = 'SESSION_UPDATE_FAILURE';

export const SESSION_DELETE_USER_BY_ID = 'SESSION_DELETE_USER_BY_ID';
export const SESSION_DELETE_USER_BY_ID_SUCCESS = 'SESSION_DELETE_USER_BY_ID_SUCCESS';
export const SESSION_DELETE_USER_BY_ID_FAILURE = 'SESSION_DELETE_USER_BY_ID_FAILURE';

export const SESSION_PASSWORD_FOR_USER_BY_ADMIN = 'SESSION_PASSWORD_FOR_USER_BY_ADMIN';
export const SESSION_PASSWORD_FOR_USER_BY_ADMIN_SUCCESS = 'SESSION_PASSWORD_FOR_USER_BY_ADMIN_SUCCESS';
export const SESSION_PASSWORD_FOR_USER_BY_ADMIN_FAILURE = 'SESSION_PASSWORD_FOR_USER_BY_ADMIN_FAILURE';

export const SESSION_PATIENT_LOGIN = 'SESSION_PATIENT_LOGIN';

export const SELECTED_PRACTICE_NAME = 'SELECTED_PRACTICE_NAME';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_USER_BY_BUSSINESS_ID_SUCCESS = 'GET_USER_BY_BUSSINESS_ID_SUCCESS';
export const GET_USER_BY_BUSSINESS_ID_FAILURE = 'GET_USER_BY_BUSSINESS_ID_FAILURE';

export const UPDATE_PROFILE_SESSION = 'UPDATE_PROFILE_SESSION';

// action types for prep
export const CHANGE_PREP_FIELD = 'CHANGE_PREP_FIELD';
export const REMOVE_CHANGE_PREP_FIELD = 'REMOVE_CHANGE_PREP_FIELD';

export const SELECTED_PREP = 'SELECTED_PREP';
export const SELECTED_PREP_SUCCESS = 'SELECTED_PREP_SUCCESS';
export const SELECTED_PREP_FAILURE = 'SELECTED_PREP_FAILURE';

export const GET_PREPS = 'GET_PREPS';
export const GET_PREPS_SUCCESS = 'GET_PREPS_SUCCESS';
export const GET_PREPS_FAILURE = 'GET_PREPS_FAILURE';

export const RESET_VALIDATION = 'RESET_VALIDATION';

export const CREATE_PREP = 'CREATE_PREP';
export const CREATE_PREP_SUCCESS = 'CREATE_PREP_SUCCESS';
export const CREATE_PREP_FAILURE = 'CREATE_PREP_FAILURE';

export const UPDATE_PREP = 'UPDATE_PREP';
export const UPDATE_PREP_SUCCESS = 'UPDATE_PREP_SUCCESS';
export const UPDATE_PREP_FAILURE = 'UPDATE_PREP_FAILURE';

export const DELETE_PREP_FAILURE = 'DELETE_PREP_FAILURE';

export const GET_PREP = 'GET_PREP';
export const GET_PREP_SUCCESS = 'GET_PREP_SUCCESS';
export const GET_PREP_FAILURE = 'GET_PREP_FAILURE';

export const GET_PREP_BY_ID = 'GET_PREP_BY_ID';
export const GET_PREP_BY_ID_SUCCESS = 'GET_PREP_BY_ID_SUCCESS';
export const GET_PREP_BY_ID_FALSE = 'GET_PREP_BY_ID_FALSE';

export const GET_QUERY_TYPE_SUCCESS = 'GET_QUERY_TYPE_SUCCESS';
export const GET_QUERY_TYPE_FAILURE = 'GET_QUERY_TYPE_FAILURE';

export const ADD_QUERY_TYPE = 'ADD_QUERY_TYPE';

export const FETCH_PREP_FORM_SUCCESS = 'FETCH_PREP_FORM_SUCCESS';

export const CREATE_PREP_FORM_SUCCESS = 'CREATE_PREP_FORM_SUCCESS';

export const SHOW_CREATE_PREP_LOADING = 'SHOW_CREATE_PREP_LOADING';

export const CREATE_PREP_FORM_FAILURE = 'CREATE_PREP_FORM_FAILURE';

export const SET_IS_VERIFY_FOR_PATIENT = 'SET_IS_VERIFY_FOR_PATIENT';

export const SET_LIST_PATIENT_CHANGED = 'SET_LIST_PATIENT_CHANGED';

//= ============================== MESSAGE =============================== //

export const ADD_MESSAGE_EVENT = 'ADD_MESSAGE_EVENT';
export const ADD_MESSAGE_EVENT_NODE = 'ADD_MESSAGE_EVENT_NODE';
export const CLOSE_MODAL_EVENT_NODE = 'CLOSE_MODAL_EVENT_NODE';
export const SET_SHOW_MODAL_EVENT_NODE = 'SET_SHOW_MODAL_EVENT_NODE';
export const SET_BUILD_TREE_NODES = 'SET_BUILD_TREE_NODES';

export const ADD_MESSAGE_EVENT_SUCCESS = 'ADD_MESSAGE_EVENT_SUCCESS';
export const ADD_MESSAGE_EVENT_FAILURE = 'ADD_MESSAGE_EVENT_FAILURE';

export const REMOVE_MESSAGE_EVENT = 'REMOVE_MESSAGE_EVENT';

export const CHANGE_FIELD_PREP_INFO = 'CHANGE_FIELD_PREP_INFO';

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SAVED_MESSAGES_RECEIVED = 'SAVED_MESSAGES_RECEIVED';

export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
export const NOTIFICATION_RECEIVED_ALL = 'NOTIFICATION_RECEIVED_ALL';

//= ============================== COMMON =============================== //
export const GET_FACILITY = 'GET_FACILITY';
export const GET_FACILITY_SUCCESS = 'GET_FACILITY_SUCCESS';
export const GET_FACILITY_FAILURE = 'GET_FACILITY_FAILURE';

export const GET_PROVIDER = 'GET_PROVIDER';
export const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS';
export const GET_PROVIDER_FAILURE = 'GET_PROVIDER_FAILURE';

export const GET_VERIFY_QUESTION_SUCCESS = 'GET_VERIFY_QUESTION_SUCCESS';
export const GET_VERIFY_QUESTION_FAILURE = 'GET_VERIFY_QUESTION_FAILURE';

export const UPDATE_TOAST_CONFIG = 'UPDATE_TOAST_CONFIG';

//= ============================== PATIENT =============================== //

export const CHANGE_PATIENT_FIELD = 'CHANGE_PATIENT_FIELD';
export const TURN_ON_MODAL_ADD_PATIENT = 'TURN_ON_MODAL_ADD_PATIENT';
export const TURN_OFF_MODAL_ADD_PATIENT = 'TURN_OFF_MODAL_ADD_PATIENT';

export const PATIENT_SAVING = 'PATIENT_SAVING';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_FAILURE = 'ADD_PATIENT_FAILURE';

export const ADD_PATIENT_UNAUTH_SUCCESS = 'ADD_PATIENT_UNAUTH_SUCCESS';
export const ADD_PATIENT_UNAUTH_FAILURE = 'ADD_PATIENT_UNAUTH_FAILURE';

export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';

export const UPDATE_PATIENT_TODAY_FILTER = 'UPDATE_PATIENT_TODAY_FILTER';
export const UPDATE_PATIENT_DATE_FILTER = 'UPDATE_PATIENT_DATE_FILTER';
export const EMPTY_PATIENT_LIST = 'EMPTY_PATIENT_LIST';

export const GET_ALL_PATIENT = 'GET_ALL_PATIENT';
export const GET_ALL_PATIENT_SUCCESS = 'GET_ALL_PATIENT_SUCCESS';
export const GET_ALL_PATIENT_SUCCESS_APPEND = 'GET_ALL_PATIENT_SUCCESS_APPEND';
export const GET_ALL_PATIENT_FAILURE = 'GET_ALL_PATIENT_FAILURE';

export const GET_ALL_APPOINTMENT_SUCCESS_APPEND = 'GET_ALL_APPOINTMENT_SUCCESS_APPEND';
export const GET_ALL_APPOINTMENT_SUCCESS = 'GET_ALL_APPOINTMENT_SUCCESS';
export const GET_ALL_APPOINTMENT_FAILURE = 'GET_ALL_APPOINTMENT_FAILURE';

export const SELECTED_PATIENT_ID = 'SELECTED_PATIENT_ID';
export const SELECTED_PATIENT = 'SELECTED_PATIENT';

export const GET_PATIENT_MESSAGE_SUCCESS = 'GET_PATIENT_MESSAGE_SUCCESS';

export const GET_PATIENT_BY_ID_SUCCESS = 'GET_PATIENT_BY_ID_SUCCESS';
export const GET_PATIENT_BY_ID_FAILURE = 'GET_PATIENT_BY_ID_FAILURE';

export const EDIT_PATIENT = 'EDIT_PATIENT';

export const GET_PATIENT_VERIFY_QUESTION_SUCCESS = 'GET_PATIENT_VERIFY_QUESTION_SUCCESS';
export const GET_PATIENT_VERIFY_QUESTION_FAILURE = 'GET_PATIENT_VERIFY_QUESTION_FAILURE';

export const VERIFY_PATIENT_FAILURE = 'VERIFY_PATIENT_FAILURE';
export const VERIFY_PATIENT_FAILURE_2 = 'VERIFY_PATIENT_FAILURE_2';

export const ENABLE_FORCE_CHANGE_BUTTON = 'ENABLE_FORCE_CHANGE_BUTTON';
export const CHANGE_FORCE_CHANGE_VALUE = 'CHANGE_FORCE_CHANGE_VALUE';
export const CHANGE_CREATE_PATIENT_NOTI_TYPE = 'CHANGE_CREATE_PATIENT_NOTI_TYPE';

export const CHANGE_PROCEDURE_TIME = 'CHANGE_PROCEDURE_TIME';
export const CHANGE_PROCEDURE_TIME_PICKER = 'CHANGE_PROCEDURE_TIME_PICKER';

export const SET_PATIENT_LOADING = 'SET_PATIENT_LOADING';
//= ============================== ACCOUNT =============================== //

export const CHANGE_TEXT_MESSAGE = 'CHANGE_TEXT_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_OBJECT = 'SEND_MESSAGE_OBJECT';

//= ============================== ACCOUNT =============================== //
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';

export const CLICK_SAVE_CHANGE_PROFILE = 'CLICK_SAVE_CHANGE_PROFILE';
export const CLICK_SAVE_CHANGE_PROFILE_SUCCESS = 'CLICK_SAVE_CHANGE_PROFILE_SUCCESS';
export const CLICK_SAVE_CHANGE_PROFILE_FAILURE = 'CLICK_SAVE_CHANGE_PROFILE_FAILURE';

export const CHANGE_TEXT_PROFILE = 'CHANGE_TEXT_PROFILE';
export const TURN_ON_MODAL_CONFIRM = 'TURN_ON_MODAL_CONFIRM';
export const TURN_OFF_MODAL_CONFIRM = 'TURN_OFF_MODAL_CONFIRM';
export const CHANGE_PROFILE_FAILURE = 'CHANGE_PROFILE_FAILURE';

//= ============================== SOCKET =============================== //
export const SOCKET_CONNECT = 'SOCKET_CONNECT';

//= ============================== MESSAGE EVENT ACTION =============================== //
export const MESSAGE_EVENT_ANSWER = 'MESSAGE_EVENT_ANSWER';

export const MESSAGE_UPDATED_RECEIVED = 'MESSAGE_UPDATED_RECEIVED';

//= ============================== RESET PASSWORD =============================== //
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';

export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
//= ============================== COMMON =============================== //
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS';

export const FETCH_ALL_PREPS_SUCCESS = 'FETCH_ALL_PREPS_SUCCESS';

export const GET_AFFILIATION_SUCCESS = 'GET_AFFILIATION_SUCCESS';

export const GET_AFFILIATION_FAILURE = 'GET_AFFILIATION_FAILURE';

export const SHOW_UPLOAD_THUMBNAIL_MODAL = 'SHOW_UPLOAD_THUMBNAIL_MODAL';

export const FETCH_ALL_BUSINESS_SUCCESS = 'FETCH_ALL_BUSINESS_SUCCESS';

export const GET_FACILITY_UNAUTH_SUCCESS = 'GET_FACILITY_UNAUTH_SUCCESS';

export const GET_PROVIDER_UNAUTH_SUCCESS = 'GET_PROVIDER_UNAUTH_SUCCESS';

export const GET_COLLABORATIONS_UNAUTH_SUCCESS = 'GET_COLLABORATIONS_UNAUTH_SUCCESS';

export const GET_FACILITY_UNAUTH_FAILURE = 'GET_FACILITY_UNAUTH_FAILURE';

export const GET_COLLABORATIONS_UNAUTH_FAILURE = 'GET_COLLABORATIONS_UNAUTH_FAILURE';

export const GET_PROVIDER_UNAUTH_FAILURE = 'GET_PROVIDER_UNAUTH_FAILURE';

export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS';

export const CHANGE_CALENDAR_EVENTS_SUCCESS = 'CHANGE_CALENDAR_EVENTS_SUCCESS';

export const CHANGE_MEDICAL_HISTORY_DATA = 'CHANGE_MEDICAL_HISTORY_DATA';

export const RESET_HAS_SERVER_ERROR_VALUE = 'RESET_HAS_SERVER_ERROR_VALUE';

export const CHANGE_PATIENT_FORM_SUBMIT_SUCCESSFULLY_VALUE = 'CHANGE_PATIENT_FORM_SUBMIT_SUCCESSFULLY_VALUE';

export const FETCH_MEDICAL_HISTORY_FORM_DATA_SUCCESS = 'FETCH_MEDICAL_HISTORY_FORM_DATA_SUCCESS';

export const CHANGE_SELECTED_DATE_OF_PATIENT_CALENDAR = 'CHANGE_SELECTED_DATE_OF_PATIENT_CALENDAR';

export const CHANGE_SELECTED_DATE_OF_TELEHEALTH_CALENDAR = 'CHANGE_SELECTED_DATE_OF_TELEHEALTH_CALENDAR';

export const FETCH_UNAVAILABLE_SLOTS_SUCCESS = 'FETCH_UNAVAILABLE_SLOTS_SUCCESS';

export const CHANGE_PATIENT_INFO_MEDICAL_HISTORY = 'CHANGE_PATIENT_INFO_MEDICAL_HISTORY';

export const GET_COLLABORATION_UNAUTH_SUCCESS = 'GET_COLLABORATION_UNAUTH_SUCCESS';

export const GET_COLLABORATION_UNAUTH_FAILURE = 'GET_COLLABORATION_UNAUTH_FAILURE';

export const FETCH_BASIC_DOCTOR_INFO_UNAUTH_SUCCESS = 'FETCH_BASIC_DOCTOR_INFO_UNAUTH_SUCCESS';

export const CHANGE_SHOW_PREVIEW = 'CHANGE_SHOW_PREVIEW';

export const UPDATE_DIAGNOSIS = 'UPDATE_DIAGNOSIS';

export const UPDATE_FILTER_PROVIDER = 'UPDATE_FILTER_PROVIDER';

//= ============================== DOCTOR REFERRAL =============================== //

export const FETCH_ALL_DOCTOR_REFERRAL_SUCCESS = 'FETCH_ALL_DOCTOR_REFERRAL_SUCCESS';

export const FETCH_ALL_DOCTOR_REFERRAL_FAILURE = 'FETCH_ALL_DOCTOR_REFERRAL_FAILURE';

export const SAVE_DOCTOR_REFERRAL_CHANGED = 'SAVE_DOCTOR_REFERRAL_CHANGED';

export const REST_DOCTOR_REFERRAL_CHANGED = 'REST_DOCTOR_REFERRAL_CHANGED';

export const CHANGE_TEXT_ADD_NEW_DOCTOR = 'CHANGE_TEXT_ADD_NEW_DOCTOR';

export const REMOVE_DOCTOR_REFERRAL_STORE = 'REMOVE_DOCTOR_REFERRAL_STORE';

export const ADD_DOCTOR_REFERRAL_STORE = 'ADD_DOCTOR_REFERRAL_STORE';

export const SEARCH_KEYWORD_DOCTOR_REFERRAL = 'SEARCH_KEYWORD_DOCTOR_REFERRAL';

export const ADD_NEW_DOCTOR_REFERRAL_NO_LOGIN = 'ADD_NEW_DOCTOR_REFERRAL_NO_LOGIN';

//= ============================== APPOINTMENT =============================== //
export const CHANGE_APPOINTMENT_FIELD = 'CHANGE_APPOINTMENT_FIELD';
export const RESET_APPOINTMENT_FIELD = 'RESET_APPOINTMENT_FIELD';

export const SAVE_APPOINTMENT = 'SAVE_APPOINTMENT';
export const APPOINTMENT_PENDING = 'APPOINTMENT_PENDING';
export const SAVE_APPOINTMENT_SUCCESS = 'SAVE_APPOINTMENT_SUCCESS';
export const SAVE_APPOINTMENT_FAILURE = 'SAVE_APPOINTMENT_FAILURE';

// review list ==//

export const GET_ALL_REVIEW_LIST_SUCCESS_APPEND = 'GET_ALL_REVIEW_LIST_SUCCESS_APPEND';
export const GET_ALL_REVIEW_LIST_SUCCESS = 'GET_ALL_REVIEW_LIST_SUCCESS';
export const GET_ALL_REVIEW_LIST_FAILURE = 'GET_ALL_REVIEW_LIST_FAILURE';

export const SAVE_REVIEW_LIST = 'SAVE_REVIEW_LIST';
export const REVIEW_LIST_PENDING = 'REVIEW_LIST_PENDING';
export const SAVE_REVIEW_LIST_SUCCESS = 'SAVE_REVIEW_LIST_SUCCESS';
export const SAVE_REVIEW_LIST_FAILURE = 'SAVE_REVIEW_LIST_FAILURE';

export const CHANGE_REVIEW_FIELD = 'CHANGE_REVIEW_FIELD';
export const RESET_REVIEW_FIELD = 'RESET_REVIEW_FIELD';

//= ============================== SURGERY =============================== //
export const CHANGE_SURGERY_FIELD = 'CHANGE_SURGERY_FIELD';
export const RESET_SURGERY_FIELD = 'RESET_APPOINTMENT_FIELD';

export const SAVE_SURGERY = 'SAVE_SURGERY';
export const SURGERY_PENDING = 'SURGERY_PENDING';
export const SAVE_SURGERY_SUCCESS = 'SAVE_SURGERY_SUCCESS';
export const SAVE_SURGERY_FAILURE = 'SAVE_SURGERY_FAILURE';

export const GET_ALL_SURGERY_SUCCESS_APPEND = 'GET_ALL_SURGERY_SUCCESS_APPEND';
export const GET_ALL_SURGERY_SUCCESS = 'GET_ALL_SURGERY_SUCCESS';
export const GET_ALL_SURGERY_FAILURE = 'GET_ALL_SURGERY_FAILURE';
export const SURGERY_REMOVE_PATIENT_BY_ID = 'SURGERY_REMOVE_PATIENT_BY_ID';

//= ============================== USER FORM =============================== //
// export const CHANGE_SURGERY_FIELD = 'CHANGE_SURGERY_FIELD';
// export const RESET_SURGERY_FIELD = 'RESET_APPOINTMENT_FIELD';

// export const SAVE_SURGERY = 'SAVE_SURGERY';
export const USER_FORM_PENDING = 'USER_FORM_PENDING';
// export const SAVE_SURGERY_SUCCESS = 'SAVE_SURGERY_SUCCESS';
// export const SAVE_SURGERY_FAILURE = 'SAVE_SURGERY_FAILURE';

export const GET_ALL_USER_FORM_SUCCESS_APPEND = 'GET_ALL_USER_FORM_SUCCESS_APPEND';
export const GET_ALL_USER_FORM_SUCCESS = 'GET_ALL_USER_FORM_SUCCESS';
export const GET_ALL_USER_FORM_FAILURE = 'GET_ALL_USER_FORM_FAILURE';

//= ============================== USER FORM DATA =============================== //
export const CHANGE_USER_FORM_DATA_FIELD = 'CHANGE_USER_FORM_DATA_FIELD';
export const RESET_USER_FORM_DATA_FIELD = 'RESET_USER_FORM_DATA_FIELD';

export const SAVE_USER_FORM_DATA = 'SAVE_USER_FORM_DATA';
export const USER_FORM_DATA_PENDING = 'USER_FORM_DATA_PENDING';
export const SAVE_USER_FORM_DATA_SUCCESS = 'SAVE_USER_FORM_DATA_SUCCESS';
export const SAVE_USER_FORM_DATA_FAILURE = 'SAVE_USER_FORM_DATA_FAILURE';

export const GET_ALL_USER_FORM_DATA_SUCCESS_APPEND = 'GET_ALL_USER_FORM_DATA_SUCCESS_APPEND';
export const GET_ALL_USER_FORM_DATA_SUCCESS = 'GET_ALL_USER_FORM_DATA_SUCCESS';
export const GET_ALL_USER_FORM_DATA_FAILURE = 'GET_ALL_USER_FORM_DATA_FAILURE';
export const USER_FORM_DATA_REMOVE_PATIENT_BY_ID = 'USER_FORM_DATA_REMOVE_PATIENT_BY_ID';

export const GET_USER_FORM_DATA_BY_PATIENT_ID_SUCCESS_APPEND = 'GET_USER_FORM_DATA_BY_PATIENT_ID_SUCCESS_APPEND';
export const GET_USER_FORM_DATA_BY_PATIENT_ID_SUCCESS = 'GET_USER_FORM_DATA_BY_PATIENT_ID_SUCCESS';
export const GET_USER_FORM_DATA_BY_PATIENT_ID_FAILURE = 'GET_USER_FORM_DATA_BY_PATIENT_ID_FAILURE';
